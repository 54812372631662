import { ApplicantPortalService, CancelError } from 'api-clients/monolith';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ReloadableResultStatus } from '../../api/resultStatus';

// New Zealand and Philippines do not get proper short_name values back from Google Maps
// So we're going to manually override them so we don't lose customers
const alternativeStateCodeMapping: Record<string, Record<string, string>> = {
  // Philippines
  PH: {
    NCR: '00', // Metro Manila
    Manila: '00', // Could also refer to Metro Manila
    Calabarzon: '40',
    Bicol: '05',
    'Cagayan Valley': '02',
    'Central Luzon': '03',
    'Central Visayas': '07',
    Caraga: '13',
    CAR: '15', // Cordillera Administrative Region
    'Cordillera Administrative': '15',
    Davao: '11',
    'Eastern Visayas': '08',
    Ilocos: '01',
    Mimaropa: '41',
    'Muslim Mindanao': '14',
    'Northern Mindanao': '10',
    Soccsksargen: '12',
    'Western Visayas': '06',
    'Zamboanga Peninsula': '09',
    'Region I': '01', // Ilocos
    'Region II': '02', // Cagayan Valley
    'Region III': '03', // Central Luzon
    'Region IV-A': '40', // Calabarzon
    'Region IV-B': '41', // Mimaropa
    'Region V': '05', // Bicol
    'Region VI': '06', // Western Visayas
    'Region VII': '07', // Central Visayas
    'Region VIII': '08', // Eastern Visayas
    'Region IX': '09', // Zamboanga Peninsula
    'Region X': '10', // Northern Mindanao
    'Region XI': '11', // Davao
    'Region XII': '12', // Soccsksargen
    'Region XIII': '13', // Caraga
    BARMM: '14', // Bangsamoro Autonomous Region in Muslim Mindanao (mapping to the 'Muslim Mindanao' option)
  },
  // New Zealand
  NZ: {
    Auckland: 'AUK',
    'Bay of Plenty': 'BOP',
    Canterbury: 'CAN',
    'Chatham Islands': 'CIT',
    Gisborne: 'GIS',
    'Hawke’s Bay': 'HKB',
    'Manawatu-Wanganui': 'MWT',
    Marlborough: 'MBH',
    Nelson: 'NSN',
    Northland: 'NTL',
    Otago: 'OTA',
    Southland: 'STL',
    Taranaki: 'TKI',
    Tasman: 'TAS',
    Waikato: 'WKO',
    Wellington: 'WGN',
    'West Coast': 'WTC',
  },
};

const getCorrectStateCode = (
  countryCode: string,
  stateCodeIso: string,
  stateCodeName: string,
): string => {
  const countryMapping = alternativeStateCodeMapping[countryCode.toUpperCase()];
  if (!countryMapping) {
    return stateCodeIso; // No mapping exists for this country
  }

  // Normalize keys and inputs to lowercase for case-insensitive lookup
  const normalizedMapping = Object.fromEntries(
    Object.entries(countryMapping).map(([key, value]) => [
      key.toLowerCase(),
      value,
    ]),
  );

  return (
    normalizedMapping[stateCodeIso.toLowerCase()] || // Try to map using state_code_iso
    normalizedMapping[stateCodeName.toLowerCase()] || // Try to map using state_code_name
    stateCodeIso // Fallback to the original state_code_iso
  );
};

export type AutocompleteResponse = Awaited<
  ReturnType<
    typeof ApplicantPortalService.getInternalApiPortalGoogleMapsAutocomplete
  >
>;

export interface AddressValues {
  // ignoring camelcase since these correspond to the name the back end is expecting
  /* eslint-disable camelcase */
  street_address: string;
  address_2: string;
  city: string;
  state_code: string;
  state_code_iso: string;
  state_code_name: string;
  postal_code: string;
  country_code: string;
  country_code_iso: string;
  latitude?: number;
  longitude?: number;
  county?: string;
  /* eslint-enable camelcase */
}

export type DetailsResponse = Awaited<
  ReturnType<
    typeof ApplicantPortalService.getInternalApiPortalGoogleMapsDetails
  >
>;

export type LoadedSuggestions =
  | ReloadableResultStatus<AutocompleteResponse>
  | { status: 'idle' };

export type LoadedAddress = ReloadableResultStatus<AddressValues>;

type AddressComponent = DetailsResponse['result']['address_components'][number];

const withAddressComponents = (components: AddressComponent[]) => {
  const findComponent = (type: string) =>
    components.find(detail => detail.types.some(label => label === type));

  const getComponentName = (type: string) =>
    findComponent(type)?.long_name ?? '';
  const getComponentShortName = (type: string) =>
    findComponent(type)?.short_name ?? '';
  return [getComponentName, getComponentShortName];
};

const withFallbacks = (fallback: string, ...args: string[]): string => {
  return args.find(s => s !== '') ?? fallback;
};

// loads place details from the API service and extracts the components into
// the format our Address inputs expect
const getPlaceDetails = async (
  placeId: string,
  sessionId: string,
): Promise<AddressValues> => {
  const details =
    await ApplicantPortalService.getInternalApiPortalGoogleMapsDetails(
      placeId,
      sessionId,
    );
  const [getComponentName, getComponentShortName] = withAddressComponents(
    details.result.address_components,
  );

  return {
    street_address: `${getComponentName('street_number')} ${getComponentName(
      'route',
    )}`,
    address_2: '',
    city:
      getComponentShortName('country') === 'NZ' &&
      getComponentName('sublocality_level_1').length > 0
        ? `${getComponentName('sublocality_level_1')}, ${getComponentName(
            'locality',
          )}`
        : withFallbacks(
            '',
            getComponentName('locality'),
            getComponentName('sublocality'),
            getComponentName('neighborhood'),
            getComponentName('administrative_area_level_2'),
          ),
    state_code: getComponentShortName('administrative_area_level_1'),
    state_code_iso: getComponentShortName('administrative_area_level_1'),
    state_code_name: getComponentName('administrative_area_level_1'),
    county: getComponentName('administrative_area_level_2'),
    country_code: getComponentName('country'),
    country_code_iso: getComponentShortName('country'),
    postal_code: getComponentName('postal_code'),
    latitude: details.result.geometry.location.lat,
    longitude: details.result.geometry.location.lng,
  };
};

type CancelToken = () => void;

const autoComplete = debounce(
  (
    setSuggestions: (newSuggestions: LoadedSuggestions) => void,
    setCancelToken: (token: CancelToken) => void,
    query: string,
    location?: string,
    sessionId?: string,
  ) => {
    const promise =
      ApplicantPortalService.getInternalApiPortalGoogleMapsAutocomplete(
        query,
        undefined,
        sessionId,
        undefined,
        location,
      );
    // only set a loading indicator if the maps api is slow to respond
    const setLoadingTimeout = setTimeout(() => {
      setSuggestions({
        isLoading: true,
        isError: false,
        status: 'loading',
      });
    }, 100);
    promise
      .then(data => {
        clearTimeout(setLoadingTimeout);
        setSuggestions({
          isLoading: false,
          isError: false,
          status: 'ready',
          data,
        });
      })
      .catch(error => {
        if (!(error instanceof CancelError)) {
          setSuggestions({
            isLoading: false,
            isError: true,
            status: 'error',
          });
        }
      });
    setCancelToken(() => {
      clearTimeout(setLoadingTimeout);
      promise.cancel();
    });
  },
  500,
);

/**
 * Uses search phrase and potentially user geolocation to get Google Maps suggestions
 * Provides a function to get the details of the suggestions. The autocomplete portion is debounced to
 * prevent excessive requests. It will also avoid making searches until a given minimum number of characters
 * are provided.
 *
 * A Maps session begins with the first autocompletion and ends with the details request.
 */
export const useSuggestedAddress = (
  phrase: string,
  minPhraseLength: number,
) => {
  const [suggestions, setSuggestions] = useState<LoadedSuggestions>({
    status: 'idle',
  });
  const cancelToken = useRef(() => {});
  const location = useRef<string>();
  const sessionId = useRef<string>();
  const isEnabled = useRef(true);

  function setCancelToken(token: CancelToken) {
    cancelToken.current = token;
  }

  // callback to store suggestions found by the Maps autocomplete API and store the session ID
  const handleSuggestions = useCallback((newSuggestions: LoadedSuggestions) => {
    setSuggestions(newSuggestions);
    if (newSuggestions.status === 'ready') {
      sessionId.current = newSuggestions.data.maps_session;
    }
  }, []);

  // hook to run auto suggestions for the given phrase as long as it meets the minimum length
  // will abort any pending http requests between changes in the phrase with the cancelToken
  useEffect(() => {
    if (phrase.length >= minPhraseLength && isEnabled.current) {
      cancelToken.current();

      autoComplete(
        handleSuggestions,
        setCancelToken,
        phrase,
        location.current,
        sessionId.current,
      );
    }
  }, [phrase, minPhraseLength, handleSuggestions]);

  // hook to request access to the user's geolocation data so we can correctly bias the searches
  useEffect(() => {
    const success: PositionCallback = position => {
      const { latitude } = position.coords;
      const { longitude } = position.coords;
      location.current = `circle:100000@${latitude},${longitude}`;
    };
    // ignoring the error callback, Maps can still make guesses without a location
    // to bias the result. Every real (modern) browser supports geolocation, this guard is for jest.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success);
    }
  }, []);

  // callback to toggle the execution of auto suggestions. Disabling will
  // also cancel any pending lookups
  const setEnabled = useCallback((enabled: boolean) => {
    if (!enabled) {
      autoComplete.cancel();
    }
    isEnabled.current = enabled;
  }, []);

  // wrapper for the place details API to use the session ID we stored and automatically disable suggestions
  // until the phrase changes again. Clears the current session ID.
  const getPlace = useCallback(
    (placeId: string) => {
      setEnabled(false);
      if (!sessionId.current) {
        throw new Error('must call getPlace with session from Autocomplete');
      }
      const currentSession = sessionId.current;
      sessionId.current = undefined;
      const tmpPlaceDetails = getPlaceDetails(placeId, currentSession);

      const newResponse = new Promise<AddressValues>((resolve, reject) => {
        tmpPlaceDetails
          .then(
            (data: {
              // eslint-disable-next-line camelcase
              country_code_iso: string;
              // eslint-disable-next-line camelcase
              state_code_iso: string;
              // eslint-disable-next-line camelcase
              state_code_name: string;
            }) => {
              const tmpData = { ...data } as AddressValues;

              // Apply corrective state code mapping
              tmpData.state_code_iso = getCorrectStateCode(
                tmpData.country_code_iso,
                tmpData.state_code_iso,
                tmpData.state_code_name,
              );

              resolve(tmpData);
            },
          )
          .catch(reject);
      });
      return newResponse;
    },
    [setEnabled],
  );

  return { suggestions, getPlace, setEnabled };
};
